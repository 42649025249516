export const QUERY_KEYS = {
  HISTORY_ENVIRONMENTS: ['history_environments'],
};

export const PARAMETER_TYPE_LABELS = {
  temperature: 'Temp',
  humidity: 'Hum',
  CO2: 'CO2',
};

export const MODE = {
  ENVIRONMENT: 'environment',
  WATERING: 'watering',
  CULTIVATION: 'cultivation',
  TASKS: 'tasks',
  NOTES: 'notes',
};

export const ENVIRONMENT_MODE = {
  ENVIRONMENT: 'environment',
  EQUIPMENT: 'equipment',
};

export const IRRIGATION_MODE = {
  WATERING: 'watering',
  WATER_QUALITY: 'water_quality',
};

export const TIMELINE_LENGTH_OPTIONS = {
  '1h': {
    key: '1',
    discriminator: '1m',
    subtract: { v: 1, k: 'hour' },
    minTimelineLength: 0,
    maxTimelineLength: 3600,
  },
  '6h': {
    key: '2',
    discriminator: '1m',
    subtract: { v: 6, k: 'hours' },
    minTimelineLength: 3600,
    maxTimelineLength: 21600,
  },
  '24h': {
    key: '3',
    discriminator: '1m',
    subtract: { v: 24, k: 'hours' },
    minTimelineLength: 21600,
    maxTimelineLength: 86400,
  },
  '1w': {
    key: '4',
    discriminator: '2h',
    subtract: { v: 1, k: 'week' },
    minTimelineLength: 86400,
    maxTimelineLength: 604800,
  },
  '1m': {
    key: '5',
    discriminator: '8h',
    subtract: { v: 1, k: 'month' },
    minTimelineLength: 604800,
    maxTimelineLength: 2592000,
  },
  '3m': {
    key: '6',
    discriminator: '1d',
    subtract: { v: 3, k: 'months' },
    minTimelineLength: 2592000,
    maxTimelineLength: 7776000,
  },
  '6m': {
    key: '7',
    discriminator: '1d',
    subtract: { v: 6, k: 'months' },
    minTimelineLength: 7776000,
    maxTimelineLength: 15552000,
  },
  '1y': {
    key: '8',
    discriminator: '1d',
    subtract: { v: 1, k: 'year' },
    minTimelineLength: 15552000,
    maxTimelineLength: 31104000,
  },
};
