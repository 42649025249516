import React, { useCallback, useEffect, useState } from 'react';

import { Snackbar, Alert } from '@mui/material';

import {
  useConfirmationDialog,
  bindConfirmationDialogState,
} from '@hooks/use-confirmation-dialog';
import { MODE } from '@features/detailed-view/constants';
import ConfirmAlert from '@components/ui/ConfirmAlert';
import { UserPermissionsService } from '@features/user-permissions/services';

import { PreLoaderWidget } from '../components/common/PreLoaderWidget';
import { APIService } from '../services/api.service';
import { asyncLocalStorage } from '../utils/asyncLocalStorage';
import { auth } from '../utils/firebase';

export const UserContext = React.createContext(undefined);

export const UserProvider = ({ children }) => {
  const confirmationDialog = useConfirmationDialog();
  const [currentUser, setCurrentUser] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacilityState] = useState(null);
  const [timezone, setTimezone] = useState(
    localStorage.getItem('facilityTimeZone') || null,
  );
  const [mode, setModeState] = useState(null);
  const [pending, setPending] = useState(true);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarProperties, setSnackbarProperties] = useState({
    severity: 'success',
    message: 'Changes saved successfully.',
  });

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      const isUserEmailVerified = user?.emailVerified;

      if (isUserEmailVerified) {
        await UserPermissionsService.get(user.uid).then(
          ({ data: { invite_pending, permissions, is_admin } }) => {
            user.invite_pending = invite_pending;
            user.permissions = permissions;
            user.is_admin = is_admin;
            if (!permissions.length && !user.is_admin) {
              alert(
                'Please talk to your administrator to setup your user permissions.',
              );
              auth.signOut().then(() => {
                window.location = '/logout';
              });
            }
          },
        );
      }
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  const fetchAndSetFacilitiesList = async () => {
    const facilities = await APIService.FACILITIES.list();
    setFacilities(facilities);
    return facilities;
  };

  const getFacilityFromStorage = async (facilities) => {
    const facilityIdInStorage = await asyncLocalStorage.getItem('facilityId');
    const selectedFacility = facilityIdInStorage
      ? facilities.find(f => f.id === facilityIdInStorage)
      : undefined;
    return selectedFacility;
  };

  const getModeFromStorage = async () => {
    const modeInStorage = await asyncLocalStorage.getItem('mode');
    return modeInStorage;
  };

  const handleSetMode = useCallback(mode => {
    if (mode) {
      asyncLocalStorage.setItem('mode', mode).then(() => {
        setModeState(mode);
      });
    }
  }, []);

  const handleSetFacility = useCallback(facility => {
    if(facility) {
      asyncLocalStorage.setItem('facilityId', facility.id).then(() => {
        setFacilityState(facility);
      });
    }
  }, []);

  const handleSetTimezone = useCallback(timezone => {
    if(timezone) {
      asyncLocalStorage.setItem('timezone', timezone).then(() => {
        setTimezone(timezone);
      });
    }
  }, []);

  useEffect(() => {
    const initializeUserStorageValues = async () => {
      if (currentUser?.emailVerified) {
        const facilities = await fetchAndSetFacilitiesList();
        if (!facilities?.length) return;

        const selectedFacility = await getFacilityFromStorage(facilities);
        const defaultFacility = facilities[0];
        const facilityToSet = selectedFacility || defaultFacility;
        handleSetFacility(facilityToSet);
        handleSetTimezone(facilityToSet.timeZone);

        const selectedMode = await getModeFromStorage();
        const defaultMode = MODE.WATERING;
        const modeToSet = selectedMode || defaultMode;
        handleSetMode(modeToSet);
      }
    };
    initializeUserStorageValues();
  }, [currentUser]);

  const handleChangeFacility = useCallback((facility, navigate) => {
    if(facility) {
      handleSetFacility(facility);
      handleSetTimezone(facility.timeZone);

      if (navigate) {
        window.location = '/';
      }
    }
  }, []);

  const showSnackbar = useCallback((type, overrideMessage) => {
    let message = 'Changes saved successfully.';
    switch (type) {
      case 'success':
        message = overrideMessage
          ? overrideMessage
          : 'Changes saved successfully.';
        break;
      case 'warning':
        message = overrideMessage ? overrideMessage : 'Warning.';
        break;
      case 'info':
        message = overrideMessage ? overrideMessage : 'Additional information.';
        break;
      case 'error':
        message = overrideMessage ? overrideMessage : 'An error occurred.';
        break;
      default:
        return;
    }

    setSnackbarProperties({
      severity: type,
      message,
    });
    setIsSnackbarOpen(true);
  }, []);

  if (pending) {
    return <PreLoaderWidget />;
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
        facilities,
        facility,
        timezone,
        mode,
        setFacility: handleChangeFacility,
        setFacilities,
        setMode: handleSetMode,
        showSnackbar,
        confirmationDialog,
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        key={'top-right'}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={snackbarProperties.severity}
        >
          {snackbarProperties.message}
        </Alert>
      </Snackbar>
      <ConfirmAlert {...bindConfirmationDialogState(confirmationDialog)} />

      {children}
    </UserContext.Provider>
  );
};
